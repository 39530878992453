import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import SamsungHeader from "../SamsungHeader";
// import Footer from "../footer";
import "./layout.scss";
import { gtmPageView } from './../../utils/utilities';
import FAQsComp from "../faqs";
import SamsungFooter from "../SamsungFooter/index";
import Popover from "../Popover";
import { PARTNER_COUNTRY, PARTNER_DETAILS } from "../../utils/constants";
import Header from "../header";
import Footer from "../footer";


function FAQsLayout({ children }) {

    const [linksVisible, setLinksVisible] = useState(false);
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        gtmPageView()
    })

    const setPopoverVisible = () => {
        setVisible(!visible);
        setLinksVisible(!visible);
    }

    const isPopoverRequired = () => {
        return process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.HK || process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.MY
    }

    const renderPageByPartner = () => {
        return (
          <>
            {process.env.GATSBY_PARTNER === PARTNER_DETAILS.SAMSUNG ? <SamsungHeader /> : <Header />}
            {isPopoverRequired() && (<Popover linksVisible={linksVisible}/>)}
            <FAQsComp />
            {process.env.GATSBY_PARTNER === PARTNER_DETAILS.SAMSUNG ? (
              <div className={`w-100 page-footer`}>
                <SamsungFooter setPopoverVisible={setPopoverVisible}/>
              </div>
            ) : (
                <Footer />
            )}
          </>
        )
      }
  return (
    <div>
      {children}
      {renderPageByPartner()}
    </div>
  );
}

FAQsLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FAQsLayout;
