import React, { useEffect, useState } from "react";
import up from "../../images/up.png";
import down from "../../images/down.png";
import { useIntl } from "gatsby-plugin-intl";
import "./faqs.scss";

function FAQsComp() {
  const intl = useIntl();
  const [state, setState] = useState({ questions: [] });
  useEffect(async () => {
    let questions = [];
    let totalFAQs = process.env.GATSBY_TOTAL_FAQs;

    for(let i=1; i<=totalFAQs; i++) {
      questions.push({
        title: intl.formatMessage({id: `faq-${i}`}),
        answer: intl.formatHTMLMessage({id: `faqAns-${i}`}),
        expanded: false
      })
    }
    
    await setState({ ...state, questions });
  }, []);

  const toggleQuestion = (question, index) => {
    const newQuestions = [...state.questions];

    newQuestions[index].expanded = !question.expanded;

    setState({ ...state, questions: newQuestions });
  };

  return (
    <section>
      <div className="faq-wrap" id="faqs">
        <div className="faq-inner main-container">
          <div className="faq-flex">
            <div className="faq-header">
            {intl.formatMessage({id: "faqHeading"})}
            </div>
            <div className="faq-q-a-flex">
              {state.questions.map((question, index) => (
                <div
                  key={`q-${index}`}
                  id={`q-${index}`}
                  className="faq-q-wrap"
                >
                  <div className=" faq-q" onClick={() => toggleQuestion(question, index)}>
                    <span className="faq-title">{question.title}</span>
                    <span className="faq-ar">
                      {question.expanded ? (
                        <img
                          src={up}
                          alt="up"
                        />
                      ) : (
                        <img
                          src={down}
                          alt="down"
                        />
                      )}
                    </span>
                  </div>

                  {question.expanded && (
                    <div className="faq-a">
                      <div
                        dangerouslySetInnerHTML={{ __html: question.answer }}
                      ></div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQsComp;
