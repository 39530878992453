import { Link } from "gatsby";
import React from "react";
import logo from "../../images/samsung/samsung_logo.svg";
import Dropdown from "react-dropdown";
import { changeLocale, IntlContextConsumer } from "gatsby-plugin-intl";
import "react-dropdown/style.css";
import "./samsungheader.scss";

function SamsungHeader() {
  const onLangChange = (lang) => {
    changeLocale(lang.value);
  };

  return (
    <header className="header-wrap">
      <div className="main-container header-content">
        <Link to="/">
            <img src={logo} alt="logo" className="logo" />
        </Link>

        <div className="lang-wrap">
          <IntlContextConsumer>
            {({ languages, language: currentLocale }) =>
              languages.length > 1 && (
                <Dropdown
                  options={languages}
                  onChange={onLangChange}
                  value={currentLocale}
                  placeholder="Lang"
                />
              )
            }
          </IntlContextConsumer>
        </div>
      </div>
    </header>
  );
}

export default SamsungHeader;
